import BudgetRadioBox from "@/component/budget-radio-box/index.vue";
import Pagination from '@/component/pagination/index.vue'
import consumer_budget_getBudgetPlanningList from '@/lib/data-service/haolv-default/consumer_budget_getBudgetPlanningList';
import consumer_budget_importBudgetPlanning from '@/lib/data-service/haolv-default/consumer_budget_importBudgetPlanning';
import consumer_budget_getBudgetOrgList from '@/lib/data-service/haolv-default/consumer_budget_getBudgetOrgList';
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
import getCompanyInfo from "@/lib/data-service/haolv-default/consumer_web_company_getCompanyInfo";
export default {
    data() {
        return {
            loading: false,
            params: {
                "budgetItemId": "",
                "budgetItemName": "",
                "budgetOrgCode": "",
                "budgetOrgName": "",
                "budgetYear": "",
                "currentPage": 1,
                "keyword": "",
                "orderBy": "",
                "pageSize": 10
            },
            total: 0,   //总条数

            cascadeList: [],
            treeDataSource: [], // 全部部门list
            activeList: [], // 筛选出来部门list
            companyInfo: {},

            tableData: [],

            dialogUploadVisible: false,
            uploadFileList: [],
            canUpload: true,
            upLoading: false,
        }
    },
    props: {},
    components: {Pagination, BudgetRadioBox},
    created() {
    },
    mounted() {
    },
    async activated() {
        // await this.getCompany();
        await this.getBudgetOrgList();
        this.treeDataSource = this.initTreeData(this.cascadeList)
        await this.getStaffList();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        toSelect(val) {
            //console.log('11111', val);
            if (this.activeList.length === 0) {
                this.params.budgetOrgCode = '';
                this.params.budgetOrgName = '';
            } else {
                /*let deptIds = []
                this.activeList.forEach(value => {
                    deptIds.push(value.deptId)
                })*/
                this.params.budgetOrgCode = val.budgetOrgId;
                this.params.budgetOrgName = val.budgetOrgName;
            }
            this.search();
        },
        delSelect() {
            this.params.budgetOrgCode = '';
            this.params.budgetOrgName = '';
            this.search();
        },
        async getBudgetOrgList() {
            let [err, res] = await awaitWrap(consumer_budget_getBudgetOrgList())
            if (err) {
                this.cascadeList = []
                return
            }
            this.companyInfo = {
                companyName: res.datas.companyName,
                companyId: res.datas.companyId,
            };
            this.cascadeList = res.datas.departmentListResults || []
        },
        initTreeData(list) {
            // 这里一定要转化，要不然他们的值监听不到变化
            let tempData = JSON.parse(JSON.stringify(list))
            let reduceDataFunc = (data, level) => {
                data.map((m, i) => {
                    m.isExpand = false // 是否展开
                    m.check = false
                    m.indeterminate = false
                    // m.children = m.children || []
                    m.level = level
                    m.bLeft = level * 15
                    m.label = m.name
                    m.value = m.deptId
                    m.children = m.childrenList
                    delete m.childrenList
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children, level + 1)
                    }
                })
            }
            reduceDataFunc(tempData, 1)
            return tempData
        },
        async getCompany() {
            let res = await getCompanyInfo()
            this.companyInfo = res.datas ? res.datas : {}
        },
        async search() {
            this.params.currentPage = 1;
            await this.getStaffList();
        },
        handleReset() {},
        handleDel() {},
        async getStaffList() {
            let [err, res] = await awaitWrap( consumer_budget_getBudgetPlanningList(this.params));
            if (err) {
                return
            }
            this.tableData = res.datas.list;
            this.total = res.datas.totalPage;
        },
        update() {
            this.dialogUploadVisible = true;
        },
        beforeUpload(val) {
            console.log(val);
            this.uploadFileList.push(val);
            return false;
        },
        handleUploadChange(file, fileList) {
            console.log(file, fileList)
            // this.fileList = fileList;
            this.uploadFileList = [file]
        },
        delFile() {
            this.uploadFileList = [];
        },
        updateSubmit() {
            // 先遍历文件大小
            let pass = true
            let outSizeFileName = ''
            this.uploadFileList.forEach(value => {
                const isLt2M = value.size / 1024 / 1024 <= 5
                if (!isLt2M) {
                    outSizeFileName = value.name
                    pass = false
                }
            })
            if (!pass) {
                this.$message({
                    type: "warning",
                    message: `上传的文件${outSizeFileName}超过5M`
                })
                return
            }
            if (!this.canUpload) {
                return
            }
            // 开始上传
            this.upLoading = true
            let fd = new FormData()
            fd.append('file', this.uploadFileList[0].raw)
            consumer_budget_importBudgetPlanning(fd).then(res => {
                this.upLoading = false
                this.dialogUploadVisible = false;
                this.$message({
                    type: "success",
                    message: '上传成功'
                })
                // this.clearPic()
                this.$refs.upload.clearFiles()
                this.uploadFileList = []

                this.search()

            }).catch(() => {
                this.upLoading = false
            })
        },
    }
}