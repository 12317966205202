// consumer_budget_importBudgetPlanning
const __request = require(`./__request/__request_contentType_json`);
const consumer_budget_importBudgetPlanning = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/budget/importBudgetPlanning',
        data: pParameter,
        headers: {
            'content-type': 'multipart/form-data',
        }
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_budget_importBudgetPlanning;